import { FormattedMessage, intlShape } from 'react-intl'
import { Link, withIntl } from '../../i18n'
import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import {
  bannerIcon,
  fluidImage,
  fourColumns,
  logos,
  pdf,
} from '../../fragments/fragments'

import Addition from '../../components/technical-benchmark/addition'
import Banner from '../../components/reusables/banner'
import { Box } from 'grommet'
import Centered from '../../components/reusables/centered'
import Documents from '../../components/reusables/documents'
import Img from 'gatsby-image'
import InputButton from '../../components/reusables/inputButton'
import Layout from '../../components/layout'
import Multiplcation from '../../components/technical-benchmark/multiplication'
import Rows from '../../components/reusables/rows'
import Tabs from '../../components/reusables/tabs'
import { injectIntl } from 'react-intl'

const Whitepaper = ({ data }) => {
  return (
    <Layout>
      <Banner data={data} shade={'light'} />
      <Documents index={0} data={data} shade={'light'} />
      <Documents index={1} data={data} shade={'grey'} />
      <Documents index={2} data={data} shade={'light'} />
    </Layout>
  )
}

export default withIntl(Whitepaper)

export const imagesQuery = graphql`
  query {
    bannerCoin: file(relativePath: { eq: "hero/bannerCoin@3x.png" }) {
      ...bannerIcon
    } 
    EN_DPoS: file(relativePath: { eq: "documents/EN_DPoS.png" }) {
      ...pdf
    }
    ZH_DPoS: file(relativePath: { eq: "documents/ZH_DPoS.png" }) {
      ...pdf
    }
    KO_DPoS: file(relativePath: { eq: "documents/KO_DPoS.png" }) {
      ...pdf
    }
    EN_Project_Whitepaper: file(relativePath: { eq: "documents/EN_Project_Whitepaper.png" }) {
      ...pdf
    }
    ZH_Project_Whitepaper: file(relativePath: { eq: "documents/ZH_Project_Whitepaper.png" }) {
      ...pdf
    }
    TR_Project_Whitepaper: file(relativePath: { eq: "documents/TR_Project_Whitepaper.png" }) {
      ...pdf
    }
    JA_Project_Whitepaper: file(relativePath: { eq: "documents/JA_Project_Whitepaper.png" }) {
      ...pdf
    }
    DE_Project_Whitepaper: file(relativePath: { eq: "documents/DE_Project_Whitepaper.png" }) {
      ...pdf
    }
    KO_Project_Whitepaper: file(relativePath: { eq: "documents/KO_Project_Whitepaper.png" }) {
      ...pdf
    }
    EN_Technical_Whitepaper: file(relativePath: { eq: "documents/EN_Technical_Whitepaper.png" }) {
      ...pdf
    }
    ZH_Technical_Whitepaper: file(relativePath: { eq: "documents/ZH_Technical_Whitepaper.png" }) {
      ...pdf
    }
    IT_Technical_Whitepaper: file(relativePath: { eq: "documents/IT_Technical_Whitepaper.png" }) {
      ...pdf
    }
    shortLastSection: file(relativePath: { eq: "hero/shortLastSection@2x.png" }) {
      childImageSharp {
        fluid(maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
